import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import 'animate.css'; // Importing animate.css


const flagScale = 1;
const flagWidth =  `100px`;

const fiflag = {
  url: require('.//images/fi.jpg'),
  x: 60,
  y: 0,
  width: flagWidth,
  scale: flagScale,
  rotate: -15,
  z: 1
  };

const jpflag = {
  url: require('.//images/jp.jpg'),
  x: 60,
  y: 0,
  width: flagWidth,
  scale: flagScale,
  rotate: 30,
  z: 2

  };

const phflag = {
  url: require('.//images/ph.jpg'),
  x: 0,
  y: 0,
  width: flagWidth,
  scale: flagScale,
  rotate: 30,
  z: 3 
  };

const gbflag = {
  url: require('.//images/gb.jpg'),
  x: 600,
  y: -100,
  width: flagWidth,
  scale: flagScale,
  rotate: -30,
  z: 1    
  };

// Intersection Observer Hook
  
    const useIntersectionObserver = (options) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef();

    useEffect(() => {

              const observer = new IntersectionObserver(([entry]) => {
                setIsIntersecting(entry.isIntersecting);
              }, options);

              if (ref.current) {
                observer.observe(ref.current);
              }

              return () => {
                if (ref.current) {
                  observer.unobserve(ref.current);
                }
              };

        }, [ref, options]);

    return [ref, isIntersecting];
  };

// Navigation Component
  const Nav = () => {
    const handleClick = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      targetElement.scrollIntoView({ behavior: 'smooth' });
    };

    return (

      <nav>
      <div class="navbar">
          <div class="nav-container">
            <input class="checkbox" type="checkbox" name="" id="" />
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
          <div class="menu-items">

              <li><a href="#section1" onClick={handleClick}></a></li>
              <li><a href="#section1" onClick={handleClick}>ホーム</a></li>
              <li><a href="#section2" onClick={handleClick}>TOP 3</a></li>
              <li><a href="#section3" onClick={handleClick}>スタッフ</a></li>
              <li><a href="#section4" onClick={handleClick}>スケジュール</a></li>
              <li><a href="#section5" onClick={handleClick}>予約</a></li>
          </div>
         
        </div>
      </div>
    </nav>
    );
  };

// Section Component
  const Section = ({ id, color, children }) => {
  
    const [ref, isIntersecting] = useIntersectionObserver({
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    return (
      <section
        id={id}
        ref={ref}
        className={`section ${isIntersecting ? '' : ''}`}
        style={{ backgroundColor: color }}
      >
        {children}
      </section>
    );
  };

  Section.propTypes = {
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };


// Heropage Component
  const Heropage = () => {



    return (
      <header className="hero">
        <div className="hero-headline-container">
          <h1>English Soccer Camp</h1>
        </div>
        <div className="hero-text-bubble">
          <p>この夏、お子様に忘れられない経験を提供します！私たちのイングリッシュサッカーキャンプは、10 - 13 歳の子供たちを対象に、サッカートレーニングと英語学習を楽しくサポートする環境で提供します.</p>
        </div>
        <div className="button-container">
          <Button />
        </div>
      </header>
    );
  };


// Heropage Image Component
    const PositionedImage = ({ url, x, y, width, scale, rotate, z  }) => {
      const imageStyle = {

        position: `absolute`,
        left: `${x}px`,
        top: `${y}px`,
        width: `${width}px`,
        height: 'auto',
        transform: `scale(${scale}) rotate(${rotate}deg)`,
        zIndex: `${z}` // Fixed z-index property

      };

      return <img src={url} alt="Positioned" style={imageStyle} />;
    };

    PositionedImage.propTypes = {
      url: PropTypes.string.isRequired,
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      scale: PropTypes.number.isRequired,
      rotate: PropTypes.number.isRequired,
    };


// Heropage Button Component
  const Button = () => {
    const [animate, setAnimate] = useState(false);

    const handleClick = () => {
      setAnimate(true);
    };
    
    const handleAnimationEnd = () => {
      setAnimate(false);
      setTimeout(() => {
        const targetElement = document.getElementById('section5');
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }, 10); // Adjust the delay to match the animation duration
    };
    
    return (
      <div
      className={`button animate__animated ${animate ? 'animate__rubberBand' : ''}`}
      onClick={handleClick}
      onAnimationEnd={handleAnimationEnd}
      >
        <div className="button-text">
          Get Started
        </div>
      </div>
    );
  };


// Featurespage Component
  const Featurespage = () => {
    return (
      <div className="features">

              <div className="features-content">
         
                <Featuresbox
                  Classname="card"
                  Featuretitle="英会話体験"
                  Featuretext="英語のスキルを向上させよう！日々の活動やトレーニングセッションは英語で行われ、自然で楽しい方法で子供たちの言語能力を高めます。"
                />
                <Featuresbox
                  Classname="card"
                  Featuretitle="楽しさとフィットネス"
                  Featuretext="アクティブに、楽しく過ごそう！私たちのキャンプには、様々なサッカードリル、ゲーム、試合が含まれ、子供たちを引きつけ、学ぶことに興奮させます。"
                />
                <Featuresbox
                  Classname="card "
                  Featuretitle="安全でサポートされた環境"
                  Featuretext="お子様の安全が最優先です。私たちは、すべての子供が成長できる安全でサポートされた環境を提供します。"
                />
              </div>
            </div>
    )

  }


// Featurespage Featuresbox Component
  const Featuresbox = ({ Featuretitle, Featuretext, Classname }) => {
    const [ref, isIntersecting] = useIntersectionObserver({
      root: null,
      rootMargin: '200px',
      threshold: 0.03,
    });

    return (
      <div ref={ref} className={`${Classname} ${isIntersecting ? 'animate__animated animate__slideInUp' : ''}`}>
        <div className="featuresbox-container">
          <h1>{Featuretitle}</h1>
          <p>{Featuretext}</p>
        </div>
      </div>
    );
  };

  Featuresbox.propTypes = {
    Featuretitle: PropTypes.string.isRequired,
    Featuretext: PropTypes.string.isRequired,
    Classname: PropTypes.string.isRequired,
  };

// Teacherpage Component
  const Teacherpage = () => {
    return (
          <div className="teacher">
            <div className="teacher-content">
              <div className="teacher-headline-container">
                <h1>Teachers</h1>
                <p>All the good things come for those who wait...</p>
              </div>
              <div className="teacher-cards-container">
                <FlippingCard 
                      frontContent={
                        <div>
                        <div className="teacher-card-main-image-container"></div>
                        <div className="teacher-card-main-info-container">Teacher A</div>

                        </div>
                      } 
                      backContent={
                          <img src={require('.//images/face2.jpg')} alt="Description of the image"></img>
                      }/>
                <FlippingCard 
                      frontContent={
                        <div>
                        <div className="teacher-card-main-image-container"></div>
                        <div className="teacher-card-main-info-container">Teacher A</div>
                        </div>
                      } 
                      backContent={
                          <img src={require('.//images/face2.jpg')} alt="Description of the image"></img>
                      }/>
                <FlippingCard 
                      frontContent={
                        <div>
                        <div className="teacher-card-main-image-container"></div>
                        <div className="teacher-card-main-info-container">Teacher A</div>
                        </div>
                      } 
                      backContent={
                          <img src={require('.//images/face2.jpg')} alt="Description of the image"></img>
                      }/>
                <FlippingCard 
                      frontContent={
                        <div>
                        <div className="teacher-card-main-image-container"></div>
                        <div className="teacher-card-main-info-container">Teacher A</div>
                        </div>
                      } 
                      backContent={
                          <img src={require('.//images/face2.jpg')} alt="Description of the image"></img>
                      }/>
                <FlippingCard 
                      frontContent={
                        <div>
                        <div className="teacher-card-main-image-container"></div>
                        <div className="teacher-card-main-info-container">Teacher A</div>
                        </div>
                      } 
                      backContent={
                          <img src={require('.//images/face2.jpg')} alt="Description of the image"></img>
                      }/>
                <FlippingCard 
                      frontContent={
                        <div>
                        <div className="teacher-card-main-image-container"></div>
                        <div className="teacher-card-main-info-container">Teacher A</div>
                        </div>
                      } 
                      backContent={
                          <img src={require('.//images/face2.jpg')} alt="Description of the image"></img>
                      }/>

                </div>
            </div>
          </div>
    );
  };

    
// Teacherpage Flippingcard Component
  const FlippingCard = ({ frontContent, backContent }) => {
      const [isFlipped, setIsFlipped] = useState(false);
      
      // A function to  handle the situation where user clicks
      const handleCardClick = () => {
        // This reads: set the isFlipped value to opposite current isFlipped value
        setIsFlipped(!isFlipped);
      };
      
      
      return (
        // This component will return a parent component which has a class of 
        // 'flipping-card' and the result of the if function -> 
        // If isFlipped is positive add 'flipped' class and 
        // If isFlipped is negative add ' ' ( add nothing in other words )

        // a ) flipping-card container is for the perspective effect
        // b ) flipping-card container is to set the 
        <div className={`flipping-card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
          
          <div className="card-front">
              {frontContent}
          </div>
          <div className="card-back">
              {/* Do add only elements you want to add, no more containers*/}
              {backContent}
          </div>
        </div>
        );
      };



// LessonSchedule Component
      const Schedulepage = () => {
        return (
          <div className='schedule-page-container'>
            <h1 className='schedule-page-title'>Schedule</h1>

            <div className='schedule-text-bubble'>
              <div className='schedule-title'>
              7月1日
              </div>
              <ul>


                  <li>
                      <div className='schedule-info-item'>

                          <div className='schedule-info-title'>時間:</div>
                      </div>
                      <div className='schedule-info-data'>午前10時から午後1時まで</div>
                  </li>
                  <li>
                    <div className='schedule-info-title' > 対象:</div>
                    <div className='schedule-info-data'>小学3年生から6年生</div>
                  </li>
                  <li>
                      <div className='schedule-info-item'>
                          <div className='check-bubble'></div>
                      </div>
                      <div className='schedule-info-data'>5人のコーチ</div>
                  </li>
                  <li>
                      <div className='schedule-info-item'>
                          <div className='check-bubble'></div>
                      </div>
                      <div className='schedule-info-data'>5人のコーチ</div>
                  </li>
                  <li>
                      <div className='schedule-info-item'>
                          <div className='check-bubble'></div>
                      </div>
                      <div className='schedule-info-data'>5人のコーチ</div>
                  </li>
                  <li>
                    <div className='schedule-info-title' > 料金:</div>
                    <div className='schedule-info-data'>5000円</div>
                  </li>

                </ul>
            </div>
          </div>
          
        )
      }





// Registrationpage GoogleFormComponent
  const Googleform = () => {
    return (
    
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdp4ZyDteRFK_L-bcbyFoq4lQXSnnSGZ_3pEZUPPzTNK1nv2Q/viewform?embedded=true" width="640" height="734" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    

    
      )
    }

// Main App Component
  function App() {
    return (
      <div className="App">
        <Nav />
        {/* container for section */}
        <div className="scroll-container">
          
          {/*HERO PAGE */}
          <Section id="section1" color="#ffffff">
            <Heropage />
          </Section>
          
          {/*FEATURES PAGE */}
          <Section id="section2" color="#e67e22">
          <Featurespage/>
      
          </Section>
          
          {/*TEACHER PAGE */}
          <Section id="section3" color="#e74c3c">
            <Teacherpage />
          </Section>
          
          {/*SCHEDULE PAGE */}
          <Section id="section4" color="#3498db">
            <Schedulepage />
          </Section>
          
          {/*GOOGLEFORM PAGE */}
          <Section id="section5" color="#FBFF00">
            <div class="googleform-container">
            </div>
            <Googleform/>
          </Section>

      </div>
      </div>
    );
    
  };
  

  export default App;
